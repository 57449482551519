<template>
    <div class="ma-auto">
        <div class="d-flex align-center justify-center mb-4">

            <div class="error-title" :class="$vuetify.breakpoint.smAndUp ? 'mt-8' : ''">
                <div class="text-18 font-weight-bold">            
                    {{ loggedInUser.userDetails.displayName }}
                </div>
                <div class="text-16 font-weight-bold">            
                    {{ loggedInUser.userDetails.email }}
                </div>
                <div class="text-14 font-weight-bold mb-5">            
                    Confirmation ID: {{ sessionId }}
                </div>
                <div class="text-18 font-weight-bold mb-5">            
                    Payment Failed
                </div>
                <div class="text-14 font-weight-bold">            
                    Your payment could not be verified. If you have been charged, please contact support and reference the confirmation ID above<br/>
                    You can attempt payment again by trying to enter your predictions, you should be prompted to pay.<br/>
                    If you do not pay before the end of Gameweek 1 your account will locked out from playing for this championship
                </div>
            </div>
        </div>
        <div class="d-flex justify-center flex-wrap">
            <v-btn small class="ma-2" outlined color="primary" @click="goToHome"
                >Back To Home</v-btn
            >
            <v-btn small class="ma-2" outlined color="danger" @click="goToFAQ"
                >Report a problem</v-btn
            >
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'Cancelled',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Cancelled'
    },
    data(){
        return {
            sessionId: null
        }
    },
    mounted(){
        this.sessionId = this.$router.currentRoute.query.session_id;
    },
    computed: {
        ...mapGetters(["loggedInUser"])
    },
    methods: {
        goToHome(){
            this.$router.push("/app/home")
        },
        goToFAQ(){
            this.$router.push('/help/faq/support')
        }
    }
}
</script>
<style lang="scss" scoped>
.error-title {
    h1 {
        font-size: 5rem;
    }
}
</style>
